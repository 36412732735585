<template>
    <div class="w-full h-full">
        <div class="border-b border-gray-300">
            Analytics
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            menu: [
                {
                    label: "Analytics",
                    route: { name: "analytics" },
                },
            ],
        };
    },
    created() {
        this.$store.commit("appModule/setMenuItems", this.menu);
    },
    beforeDestroy() {
        this.$store.commit("appModule/setMenuItems", []);
    },
};
</script>
